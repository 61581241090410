import React from 'react';
import { GetStaticProps, GetStaticPaths } from 'next';
import logger from 'helpers/logger';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { DXPContentPage } from 'pages/_app';
import { getCMSDataProperties } from 'application/repositories/CmsRepository';

//DDS skal fjernes når lokal test er ok
// import { getPageContent } from 'application/repositories/pageContentRepository';
// import { getChildrenContent } from 'application/repositories/childrenContentRepository';
// import { getDictionaryItems } from 'application/repositories/dictionaryRepository';
// import { getNavigationData } from 'application/repositories/navigationRepository';
// import { getSite } from 'application/repositories/siteRepository';
// import { getAllDataFromCms } from 'application/adapters/cms/cmsAdapter';

const Page: DXPContentPage<PageProps> = (pageProps) => {
	const { page } = pageProps ?? {};
	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page} />
		</LayoutFeature>
	);
};

type PageProps = {
	content: Content.PageContent;
	navigation: Navigation.NavigationItem;
	dictionary: Content.DictionaryItem[];
	site: Models.Site;
	locale: string;
};

export const getStaticPaths: GetStaticPaths = async () => {
	return {
		paths: [],
		// In the current setup fallback MUST be blocking
		// If fallback is set to true next will try to return the
		// page with an initially empty data-set, so that you can show
		// a loading page or do other operations until data is available
		// https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#fallback-blocking
		fallback: 'blocking',
	};
};

export const getStaticProps: GetStaticProps<PageProps> = async ({ params, preview, previewData: untypedPreviewData, locale }) => {
	const { host, page } = params;
	const url = page ? (page as string[]).join('/') : '';
	const previewData = untypedPreviewData as Models.PreviewData;

	// custom redirect to midigate 
	var regex = /^(?!media).*\.png/gm
	if(url.startsWith("_layouts/") || regex.test(url)){
		return {
			notFound: true,
		};
	}
	

	

	const profiler = logger.startTimer();
	

	try {
		
		//const dictionaryPromise = getDictionaryItems({ host, preview, previewData });
		//const navigationPromise = getNavigationData({ url, host, preview, previewData, locale });
		//const sitePromise = getSite({ url, host, preview, previewData, locale });
		// const contentPromise = getPageContent({
		// 	url,
		// 	host,
		// 	preview,
		// 	previewData,
		// 	locale,
		// });

		// const childrenContentPromise = getChildrenContent({
		// 	url,
		// 	host,
		// 	preview,
		// 	previewData,
		// 	locale,
		// });

		const CMSDataPromise = getCMSDataProperties({ url, host, preview, previewData, locale })
		const { pageContent, navigation, dictionary, site } = await CMSDataPromise;
		//console.log('DDS_old_site',await sitePromise,'DDS_new_site',site);
		return {
			props: {
				content: pageContent,
				navigation: navigation,
				dictionary: dictionary,
				site: site,
				locale: locale,
			},
			revalidate: 200,
		};
	} catch (error) {
		const { statusCode } = error;

		if (statusCode === 404) {
			return {
				notFound: true,
				revalidate: 200,
			};
		}
		if (statusCode === 301 || statusCode === 302 || statusCode === 307 || statusCode === 308) {
			return {
				redirect: {
					destination: error.url,
					permanent: statusCode === 301 || statusCode === 307,
				},
			};
		}
		logger.error(error);
		throw error;
	} finally {
		profiler.done({
			message: `getServerSideProps`,
			level: 'info',
		});
		logger
	}
};

export default Page;
